import { fetcher } from "@/utils/api"

// export const getContracts = () => {
//   return fetcher("/contracts")
// }

// export const getContractsByTemplate = (template) => {
//   return fetcher(`/contracts/templates/${template}`)
// }

// export const signContracts = (uid) => {
//   return fetcher(`/contracts/${uid}/sign`)
// }

// export const createContract = ({
//   project,
//   parties = [],
//   template = "",
//   customer,
//   content,
//   variables,
// }) => {
//   return fetcher("/contracts", {
//     method: "POST",
//     body: JSON.stringify({
//       project,
//       parties,
//       content,
//       template,
//       customer,
//       variables,
//     }),
//   })
// }

export const getContracts = async (): Promise<any> => {
  try {
    const response = await fetcher("/contracts")
    return response
  } catch (error) {
    console.error("Failed to fetch contracts:", error)
    return undefined
  }
}

export const getContractsByTemplate = async (
  template: string,
): Promise<any> => {
  try {
    const response = await fetcher(`/contracts/templates/${template}`)
    return response
  } catch (error) {
    console.error("Failed to fetch contracts by template:", error)
    return undefined
  }
}

export const signContracts = async (uid: string): Promise<any> => {
  try {
    const response = await fetcher(`/contracts/${uid}/sign`)
    return response
  } catch (error) {
    console.error("Failed to sign contracts:", error)
    return undefined
  }
}

export const createContract = async ({
  project,
  parties = [],
  template = "",
  customer,
  content,
  variables,
}: {
  project: string
  parties?: any[]
  template?: string
  customer: string
  content: any
  variables: any
}): Promise<any> => {
  try {
    const response = await fetcher("/contracts", {
      method: "POST",
      body: JSON.stringify({
        project,
        parties,
        content,
        template,
        customer,
        variables,
      }),
    })
    return response
  } catch (error) {
    console.error("Failed to create contract:", error)
    return undefined
  }
}
